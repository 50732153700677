<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap>
      <v-flex xs12>
        <v-layout justify-center wrap>
          <v-flex xs12 pb-5>
            <span class="homeTop">Product Details</span>
          </v-flex>
        </v-layout>
        <v-form v-model="addslider" ref="addcat">
          <v-layout justify-center wrap v-if="product">
            <v-flex xs12>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="product.order"
                  type="number"
                  required
                  label=" Order"
                ></v-text-field>
              </v-flex>
              <v-text-field
                class="homeText"
                outlined
                dense
                v-model="product.name"
                label=" Name of the Product"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                class="homeText"
                outlined
                dense
                v-model="product.title"
                label=" Title of the Product"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 pb-5>
              <span class="homeText">Description of the Product</span>
              <div id="app">
                <vue-editor v-model="product.description"></vue-editor>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                class="homeText"
                outlined
                dense
                v-model="product.weblink"
                required
                label=" Website Link"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                class="homeText"
                outlined
                dense
                v-model="product.applink"
                required
                label=" App Link"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                class="homeText"
                outlined
                multiple
                dense
                :items="categoryArray"
                item-text="name"
                item-value="_id"
                v-model="product.category"
                label=" Select Category"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-select
                class="homeText"
                outlined
                multiple
                dense
                :items="subcategoryArray"
                item-text="name"
                item-value="_id"
                v-model="product.subCategory"
                label=" Select SubCategory"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-select
                class="homeText"
                outlined
                dense
                :items="clientArray"
                item-text="company"
                item-value="_id"
                v-model="product.client"
                label=" Select Client"
              ></v-select>
            </v-flex>
            <v-layout justify-center wrap>
              <v-flex xs12>
                <span class="homeText"> Color</span>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-color-picker
                dot-size="25"
                hide-mode-switch
                mode="hexa"
                swatches-max-height="200"
                v-model="product.color"
              ></v-color-picker>
            </v-flex>
            <v-layout justify-center wrap>
              <v-flex xs12>
                <span class="homeText">Font Color</span>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-color-picker
                dot-size="25"
                hide-mode-switch
                mode="hexa"
                swatches-max-height="200"
                v-model="product.fontColor"
              ></v-color-picker>
            </v-flex>
            <v-flex md12 xs12 pb-5>
              <img
                v-if="imagePreview"
                :src="imagePreview"
                v-show="showPreview"
                width="150px"
                height="150px"
                class="align-center"
                contain
              />
              <img
                v-else
                :src="mediaURL + product.image"
                width="150px"
                height="150px"
                class="align-center"
                contain
              />
            </v-flex>
            <v-layout justify-start wrap>
              <v-flex xs12 sm7 md5 lg4>
                <v-btn
                  width="180px"
                  block
                  tile
                  depressed
                  :ripple="false"
                  class="text-capitalize"
                  color="#F6F5FF"
                  @click="$refs.showImage.click()"
                >
                  <v-layout justify-center wrap>
                    <v-flex xs2 sm2 md2 text-left>
                      <v-icon style="color: #000000">mdi-upload</v-icon>
                    </v-flex>
                    <v-flex xs10 sm8 md8>
                      <span class="image">UPLOAD IMAGE </span>
                    </v-flex>
                  </v-layout>
                </v-btn>
                <input
                  v-show="false"
                  accept="image/*"
                  id="file"
                  ref="showImage"
                  multiple="multiple"
                  type="file"
                  @change="showImage"
                />
              </v-flex>
            </v-layout>
            <v-flex md12 xs12 pb-5>
              <img
                v-if="imagePreviewCover"
                :src="imagePreviewCover"
                v-show="showPreviewCover"
                width="150px"
                height="150px"
                class="align-center"
                contain
              />
              <img
                v-else
                :src="mediaURL + product.coverImage"
                width="150px"
                height="150px"
                class="align-center"
                contain
              />
            </v-flex>
            <v-layout justify-start wrap>
              <v-flex xs12 sm7 md5 lg4>
                <v-btn
                  width="180px"
                  block
                  tile
                  depressed
                  :ripple="false"
                  class="text-capitalize"
                  color="#F6F5FF"
                  @click="$refs.showImageCover.click()"
                >
                  <v-layout justify-sta wrap>
                    <v-flex xs2 sm2 md2 text-left>
                      <v-icon style="color: #000000">mdi-upload</v-icon>
                    </v-flex>
                    <v-flex xs10 sm8 md8>
                      <span class="image">UPLOAD COVER IMAGE </span>
                    </v-flex>
                  </v-layout>
                </v-btn>
                <input
                  v-show="false"
                  accept="image/*"
                  id="file"
                  ref="showImageCover"
                  multiple="multiple"
                  type="file"
                  @change="showImageCover"
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-form>
        <v-layout justify-center wrap>
          <v-flex xs12 sm12 lg5 pb-3 pt-10 pb-sm-0>
            <v-btn
              color="black"
              tile
              @click="edit()"
              width="100%"
              style="text-transform: none; color: white"
              elevation="0"
              >EDIT
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      product: {
        name: null,
        title: null,
        description: null,
        category: null,
        subCategory: null,
        client: null,
        color: null,
        fontColor: null,
        order: null,
        weblink: null,
        applink: null,
      },
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      addslider: false,
      list: [],
      editingitem: [],
      categoryArray: [],
      subcategoryArray: [],
      clientArray: [],
      dialog: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      imagePreviewCover: "",
      showPreviewCover: false,
      file1: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      coverimageFile: null,
      imageFiles: [],
      id: null,
    };
  },
  mounted() {
    this.getList();
    this.getCat();
    this.getClient();
    this.getSub();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "topimage") {
        this.topimageFile = window_data.selectedFiles;
      } else if (window_data.type == "coverimage") {
        this.coverimageFile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.product.name) {
        this.msg = "Please Provide Name of the Product";
        this.showSnackBar = true;
        return;
      } else if (!this.product.title) {
        this.msg = "Please Provide Title of the Product";
        this.showSnackBar = true;
        return;
      } else if (!this.product.description) {
        this.msg = "Please Provide Description of the Product";
        this.showSnackBar = true;
        return;
      } else if (!this.product.category) {
        this.msg = "Please Provide Category of the Product";
        this.showSnackBar = true;
        return;
      } else if (!this.product.client) {
        this.msg = "Please Provide Client of the Product";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    getList() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/product/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.product,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.product = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getCat() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/product/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArray = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getSub() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/product/subcategory/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.subcategoryArray = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getClient() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/home/client/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.clientArray = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    showImageCover(e) {
      this.file1 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImageCover.value = null;
        this.imagePreviewCover = e.target.result;
      };
      if (this.file1.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showPreviewCover = true;
      if (this.file1) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file1.name)) {
          reader.readAsDataURL(this.file1);
        }
      }
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImage.value = null;
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage(item) {
      store.commit("appLoading", true);
      this.formData.append("id", item);
      this.formData.append("image", this.file);
      axios({
        method: "POST",
        url: "/product/image",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status == true) {
            this.formData = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.dialog = false;
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    uploadCoverImage(item) {
      store.commit("appLoading", true);
      let formData = new FormData();
      formData.append("id", item);
      formData.append("image", this.file1);
      axios({
        method: "POST",
        url: "/product/coverimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status == true) {
            // this.formDataCover = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.dialog = false;
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      this.product["id"] = this.$route.query.product;
      axios({
        method: "POST",
        url: "/product/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.product,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.imagePreview) {
              this.uploadImage(this.$route.query.product);
            }
            if (this.imagePreviewCover) {
              this.uploadCoverImage(this.$route.query.product);
            }
            this.msg = "Updated Successfully";
            this.showSnackBar = true;
            this.getList();
          } else {
            this.msg = "Can't Update";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>